!(function() {
  "use strict";
  window.addEventListener("message",(function(a) {
    if(void 0!==a.data["datawrapper-height"])
      for (var e in a.data["datawrapper-height"]) {
        var t=document.getElementById("datawrapper-chart-"+e)||document.querySelector("iframe[src*='"+e+"']");
        t && (t.style.height=a.data["datawrapper-height"][e]+"px");
      }
  }));
}());
